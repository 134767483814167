import React, { createContext, useState, useContext, useCallback } from "react";
import { useAuth } from "./AuthContext";

const FilesUploadContext = createContext();

export const FilesUploadProvider = ({ children }) => {
    const [uploadFiles, setUploadFiles] = useState([]);
    const { authState } = useAuth();

    const addFile = useCallback((file) => {
        if (!authState.isAuthenticated) return;
        setUploadFiles((prevFiles) => [...prevFiles, file]);
    }, [authState]);

    const removeFile = useCallback((fileName) => {
        if (!authState.isAuthenticated) return;
        setUploadFiles((prevFiles) =>
            prevFiles.filter((file) => file.fileName !== fileName)
        );
    }, [authState]);

    const clearFiles = useCallback(() => {
        if (!authState.isAuthenticated) return;
        setUploadFiles([]);
    }, [authState]);

    const listFiles = useCallback(() => {
        if (!authState.isAuthenticated) return;
        return uploadFiles;
    }, [uploadFiles, authState]);

    const findFile = useCallback((fileName) => {
        if (!authState.isAuthenticated) return;
        return uploadFiles.some((file) => file.fileName === fileName);
    }, [authState, uploadFiles]);
    
    return (
        <FilesUploadContext.Provider
            value={{ uploadFiles, addFile, removeFile, findFile, clearFiles, listFiles }}
        >
            {children}
        </FilesUploadContext.Provider>
    );
};

export const useFilesUpload = () => useContext(FilesUploadContext);
