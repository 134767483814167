import * as React from "react";
import { Container } from "react-bootstrap";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloudIcon from "@mui/icons-material/Cloud";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute/ProtectedRoute";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { StorageProvider } from "./contexts/StorageContext";
import { FilesUploadProvider } from "./contexts/FilesUploadContext";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";


const ScrollToTop = lazy(() => import("./utils/ScrollToTop/ScrollToTop"));
const Home = lazy(() => import("./pages/Home/Home"));
const Login = lazy(() => import("./pages/Login/Login"));
const Storage = lazy(() => import("./pages/Storage/Storage"));

const pages = [
    {
        element: (
            <ProtectedRoute>
                <Home />
            </ProtectedRoute>
        ),
        path: "/",
    },
    { element: <Login />, path: "/login" },
];

const drawerWidth = 200;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

function App() {
    const authState = useAuth();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    let content = (
        <>
            <Routes>
                {pages.map((page, index) => (
                    <Route
                        key={index}
                        path={page.path}
                        element={page.element}
                    />
                ))}
            </Routes>
        </>
    );

    return (
        <div className="d-flex flex-column min-vh-100">
            <Router>
                <AuthProvider>
                    <StorageProvider>
                        <FilesUploadProvider>
                            <Suspense
                                fallback={
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            color: "#D30000",
                                            fontSize: "40px",
                                        }}
                                    >
                                        Loading...
                                    </div>
                                }
                            >
                                <Box sx={{ display: "flex" }}>
                                    <CssBaseline />
                                    <AppBar
                                        position="fixed"
                                        open={open}
                                        className="bg-dark"
                                    >
                                        <Toolbar>
                                            <IconButton
                                                color="inherit"
                                                aria-label="open drawer"
                                                onClick={handleDrawerOpen}
                                                edge="start"
                                                sx={{
                                                    mr: 2,
                                                    ...(open && {
                                                        display: "none",
                                                    }),
                                                }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                            <Typography
                                                variant="h6"
                                                noWrap
                                                component="div"
                                            >
                                                Storage Controller
                                            </Typography>
                                        </Toolbar>
                                    </AppBar>
                                    <Drawer
                                        sx={{
                                            width: drawerWidth,
                                            flexShrink: 0,
                                            "& .MuiDrawer-paper": {
                                                width: drawerWidth,
                                                boxSizing: "border-box",
                                            },
                                        }}
                                        variant="persistent"
                                        anchor="left"
                                        open={open}
                                    >
                                        <DrawerHeader>
                                            <IconButton
                                                onClick={handleDrawerClose}
                                            >
                                                {theme.direction === "ltr" ? (
                                                    <ChevronLeftIcon />
                                                ) : (
                                                    <ChevronRightIcon />
                                                )}
                                            </IconButton>
                                        </DrawerHeader>
                                        <Divider />
                                        <List>
                                            <Link
                                                to="/"
                                                className="text-decoration-none text-dark"
                                            >
                                                <ListItem disablePadding>
                                                    <ListItemButton>
                                                        <ListItemIcon>
                                                            <CloudIcon />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            Home
                                                        </ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        </List>

                                        <Divider />
                                    </Drawer>
                                    <Main open={open}>
                                        <DrawerHeader />
                                        <Container fluid>{content}</Container>
                                    </Main>
                                </Box>
                            </Suspense>
                            <ScrollToTop />
                        </FilesUploadProvider>
                    </StorageProvider>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;
