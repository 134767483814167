import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        user: null,
        token: null,
    });

    const navigate = useNavigate();

    const login = (user, token) => {
        setAuthState({ isAuthenticated: true, user, token });
        navigate("/");
    };

    const logout = async () => {
        try {
            await fetch("/logout", {
                method: "POST",
                credentials: "include",
            });
            setAuthState({ isAuthenticated: false, user: null, token: null, });
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    return (
        <AuthContext.Provider value={{ authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
