import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
} from "react";
import { useAuth } from "./AuthContext";

const StorageContext = createContext();

export const StorageProvider = ({ children }) => {
    const [fileData, setFileData] = useState([]);
    const [storageInfo, setStorageInfo] = useState(null);
    const { authState } = useAuth();

    const fetchStorageData = useCallback(async () => {
        if (!authState.isAuthenticated) return;

        try {
            const response = await fetch("/v1/storage/get/all", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authState.token}`,
                },
            });
            const data = await response.json();
            setFileData(data[0]);
            setStorageInfo(data[1][0]);
        } catch (error) {
            console.error("Error fetching storage data:", error);
        }
    }, [authState]);

    useEffect(() => {
        fetchStorageData();
    }, [authState, fetchStorageData]);

    return (
        <StorageContext.Provider
            value={{ fileData, storageInfo, fetchStorageData }}
        >
            {children}
        </StorageContext.Provider>
    );
};

export const useStorage = () => useContext(StorageContext);
